// 配列の要素を16進数で返します。
export const arrayToHex = (argData: any) => {
  let retVal = ''
  for (let val of argData) {
    let str = val.toString(16)
    str = val < 0x10 ? '0' + str : str
    retVal += str.toUpperCase() + ' '
  }
  return retVal
}
