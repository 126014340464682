export const hexToAscii = (hexString: string) => {
  // 16 進文字列を Uint8Array に変換
  const bytes = new Uint8Array(hexString.length / 2)
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = parseInt(hexString.substr(i * 2, 2), 16)
  }

  // Uint8Array を ASCII 文字列に変換
  const decoder = new TextDecoder('ascii')
  const ascii = decoder.decode(bytes)
  return ascii
}
