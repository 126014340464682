import axios from 'axios'
import { formatToTimeZone } from 'date-fns-timezone'
const FORMAT = 'YYYY/MM/DD HH:mm:ss'
const timeZone = 'Asia/Tokyo'

interface ReportData {
  data: {
    result: string
  }
  error: any
  error_code?: 0
}

export const postReportAndCount = async (
  card_id: string,
  branch_id: string,
): Promise<ReportData> => {
  const REPORT_AND_COUNT_URL = process.env.REACT_APP_API_REPORT_AND_COUNT_URL
  const currentTime = formatToTimeZone(new Date(), FORMAT, { timeZone })
  const data = {
    branch_id,
    report_id: '1040', // 万代院の修行コード
    user_id: card_id,
    report_date: currentTime,
    is_count_total: 1,
    is_count_this_year: 1,
    is_count_branch_report: 1,
  }
  console.log({ data })
  const config = {
    method: 'post',
    url: REPORT_AND_COUNT_URL,
    data: data,
  }
  const response = await axios(config)
    .then((response) => {
      console.log('report and count response data: ', response.data)
      return response.data
    })
    .catch((error) => {
      console.log(error)
      return { error_code: 1, error }
    })
  return response
}
