// DataViewの8ビットバイナリを16進数で返します。
export const binArrayToHex = (argData: any) => {
  let retVal = ''
  for (let idx = 0; idx < argData.byteLength; idx++) {
    let bt = argData.getUint8(idx)
    let str = bt.toString(16)
    str = bt < 0x10 ? '0' + str : str
    retVal += str.toUpperCase() + ' '
  }
  return retVal
}
