// リクエストヘッダーの付加
export const addReqHeader = (argData: any, seqNumber: number) => {
  const dataLen = argData.length
  const SLOTNUMBER = 0x00

  let retVal = new Uint8Array(10 + dataLen)

  retVal[0] = 0x6b // ヘッダー作成
  retVal[1] = 255 & dataLen // length をリトルエンディアン
  retVal[2] = (dataLen >> 8) & 255
  retVal[3] = (dataLen >> 16) & 255
  retVal[4] = (dataLen >> 24) & 255
  retVal[5] = SLOTNUMBER // タイムスロット番号
  retVal[6] = ++seqNumber // 認識番号

  0 !== dataLen && retVal.set(argData, 10) // コマンド追加

  return retVal
}
