import React, { FC }  from "react";
import { useNavigate } from 'react-router-dom'
import { TopSelect } from '../../components/TopSelect';

const OyasonoFelicaRead: FC = () => {
  const navigate = useNavigate();
  const branch_id = "28001";
  const branch_name = "bandaiin";

  const felicaFunc = async () => {
    navigate(`/read`, { state: { branch: branch_name, branch_id } });
  }
  
  return (
    <div className="flex flex-col items-center bg-center bg-cover h-screen justify-center">
      <TopSelect felicaFunc={felicaFunc} branch={branch_name} />
    </div>
  );
};

export default OyasonoFelicaRead;
