import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button';
import { BackgroundImages } from '../components/BackgroundImages';

interface Props {
  branch: string,
  felicaFunc: () => void,
}

export const TopSelect: FC<Props> = (props) => {
  const card_image = '/material/card_image.png';
  const hand_image = '/material/hand_image.png';

  return (
    <>
        <BackgroundImages category={1} />
        {process.env.REACT_APP_ENV === 'staging' && <div className="bg-slate-300 w-1/3 mx-auto p-4 flex items-center justify-center absolute top-0">これはステージング環境です</div>}
        <h1 className="text-center tracking-wide pt-4 py-4 text-5xl font-bold" style={{ textShadow: "1px 1px 2px silver" }}>万代院参拝奉告</h1>
        <p className="text-4xl font-bold">奉告する方法を選んでください</p>
        <div className="w-full flex justify-between mt-10">
          <div className="rounded-md mr-5">
            <Button variant="contained" onClick={props.felicaFunc} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: 600, fontSize: '32px', padding: '28px 90px', backgroundColor: '#FF9F29' }}>
                <img className="w-full" src={card_image} alt="" />
                <p className="mt-8 font-bold text-4xl">カードをかざす</p>
            </Button>
          </div>
          <div className="rounded-md ml-5">
            <Link to={`/${props.branch}/keyboard`}>
                <Button variant="contained" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: 600, fontSize: '32px', padding: '29px 90px', backgroundColor: '#68B984' }}>
                    <img className="w-3/5" src={hand_image} alt="" />
                    <p className="mt-6 font-bold text-4xl">手で入力をする</p>
                </Button>
            </Link>
          </div>
        </div>
    </>
  );
};
