import { usbDeviceCommunicationThruEX } from './usbDeviceCommunicationThruEX'
import { usbDeviceCommunicationThruEXResponse } from './usbDeviceCommunicationThruEXResponse'
import { addReqHeader } from './addReqHeader'
import { getEndPoint } from './getEndPoint'
import { binArrayToHex } from './binArrayToHex'
import { arrayToHex } from './arrayToHex'
import { hexToAscii } from './hexToAscii'
import { sleep } from './sleep'

interface USBConfiguration {
  confValue: number
  interfaceNum: number
  endPointInNum: number
  endPointInPacketSize: number
  endPointOutNum: number
  endPointOutPacketSize: number
}

interface ResultData {
  cardNumber: string
  systemCode: number
}

const felicaRead = async (intervalId: number): Promise<ResultData> => {

  let usbDevice: USBDevice = {
    productName: '',
    manufacturerName: '',
    serialNumber: '',
    deviceClass: 0,
    deviceSubclass: 0,
    deviceProtocol: 0,
    vendorId: 0,
    productId: 0,
    usbVersionMajor: 0,
    usbVersionMinor: 0,
    usbVersionSubminor: 0,
    deviceVersionMajor: 0,
    deviceVersionMinor: 0,
    deviceVersionSubminor: 0,
    open: () => Promise.reject('Device not connected'),
    close: () => Promise.reject('Device not connected'),
    selectConfiguration: () => Promise.reject('Device not connected'),
    claimInterface: () => Promise.reject('Device not connected'),
    releaseInterface: () => Promise.reject('Device not connected'),
    selectAlternateInterface: () => Promise.reject('Device not connected'),
    controlTransferIn: () => Promise.reject('Device not connected'),
    controlTransferOut: () => Promise.reject('Device not connected'),
    clearHalt: () => Promise.reject('Device not connected'),
    transferIn: () => Promise.reject('Device not connected'),
    transferOut: () => Promise.reject('Device not connected'),
    isochronousTransferIn: () => Promise.reject('Device not connected'),
    isochronousTransferOut: () => Promise.reject('Device not connected'),
    configurations: [],
    opened: false,
    forget: () => Promise.reject('Device not connected'),
    reset: () => Promise.reject('Device not connected'),
    configuration: {
      configurationValue: 0,
      interfaces: [],
    },
  }
  let usbConfiguration: USBConfiguration = {
    confValue: 0,
    interfaceNum: 0,
    endPointInNum: 0,
    endPointInPacketSize: 0,
    endPointOutNum: 0,
    endPointOutPacketSize: 0,
  }
  let seqNumber = 0
  let resultData: ResultData = {
    cardNumber: '',
    systemCode: 0,
  }

  const DeviceFilter = [
    { vendorId: 1356, productId: 3528 }, // SONY PaSoRi RC-S300/S
    { vendorId: 1356, productId: 3529 }, // SONY PaSoRi RC-S300/P
  ]

  // USBデバイスへデータを渡す
  const sendUSB = async (argData: any, argProc = '') => {
    argProc === '' && console.log({ argData })
    const rdData = await addReqHeader(argData, seqNumber)
    await usbDevice.transferOut(usbConfiguration.endPointOutNum, rdData)
    arrayToHex(rdData)
  }

  // USBデバイスからデータを受け取る
  const recvUSB = async (argLength: any) => {
    const res = await usbDevice.transferIn(
      usbConfiguration.endPointInNum,
      argLength,
    )
    binArrayToHex(res.data)
    return res
  }

  // USB デバイスコネクト
  const usbDeviceConnect = async () => {
    console.log('usbDeviceConnect')
    const ud = await navigator.usb.getDevices() // ペアリング設定済みデバイスのUSBDeviceインスタンス取得
    let peared = 0
    if (ud.length > 0) {
      for (let dev of ud) {
        const td = DeviceFilter.find(
          (fildev) =>
            dev.vendorId === fildev.vendorId &&
            dev.productId === fildev.productId,
        )
        if (td !== undefined) {
          ++peared
          usbDevice = dev
        }
      }
    }
    if (peared !== 1) {
      // USB機器をペアリングフローから選択しデバイスのUSBDeviceインスタンス取得
      usbDevice = await navigator.usb.requestDevice({ filters: DeviceFilter })
    }
    if (usbDevice.configuration) {
      usbConfiguration.confValue = usbDevice.configuration.configurationValue
      usbConfiguration.interfaceNum =
        usbDevice.configuration.interfaces[
          usbConfiguration.confValue
        ].interfaceNumber // インターフェイス番号

      // 入力エンドポイントを求める
      let ep = getEndPoint(
        usbDevice.configuration.interfaces[usbConfiguration.confValue],
        'in',
      )
      usbConfiguration.endPointInNum = ep.endpointNumber // 入力エンドポイント
      usbConfiguration.endPointInPacketSize = ep.packetSize // 入力パケットサイズ
      // 出力エンドポイントを求める
      ep = getEndPoint(
        usbDevice.configuration.interfaces[usbConfiguration.confValue],
        'out',
      )
      usbConfiguration.endPointOutNum = ep.endpointNumber // 出力エンドポイント
      usbConfiguration.endPointOutPacketSize = ep.packetSize // 出力パケットサイズ
    }
    return
  }

  // USB デバイスオープン
  const usbDeviceOpen = async () => {
    console.log('usbDeviceOpen')
    await usbDevice.open() // USBデバイスセッション開始
    await usbDevice.selectConfiguration(usbConfiguration.confValue) // USBデバイスの構成を選択
    await usbDevice.claimInterface(usbConfiguration.interfaceNum) // USBデバイスの指定インターフェイスを排他アクセスにする

    // RC-S300 コマンド
    const endTransparent = [0xff, 0x50, 0x00, 0x00, 0x02, 0x82, 0x00, 0x00]
    const startransparent = [0xff, 0x50, 0x00, 0x00, 0x02, 0x81, 0x00, 0x00]
    const turnOff = [0xff, 0x50, 0x00, 0x00, 0x02, 0x83, 0x00, 0x00]
    const turnOn = [0xff, 0x50, 0x00, 0x00, 0x02, 0x84, 0x00, 0x00]

    await sendUSB(endTransparent, 'End Transeparent Session')
    await recvUSB(64)

    await sendUSB(startransparent, 'Start Transeparent Session')
    await recvUSB(64)

    await sendUSB(turnOff, 'Turn Off RF')
    await sleep(50)
    await recvUSB(64)
    await sleep(50)

    await sendUSB(turnOn, 'Turn On RF')
    await sleep(50)
    await recvUSB(64)
    await sleep(50)

    return
  }

  // FeliCa 操作 (communicateThruEX を使って FeliCa カードを操作する。 )
  const felica = async () => {
    console.log('felica')
    // FeliCa Lite-S コマンド
    const polling = [0x00, 0xff, 0xff, 0x01, 0x00] // ポーリング コマンド
    const pollingCom = await usbDeviceCommunicationThruEX(polling)
    let res

    await sendUSB(pollingCom, 'Polling')
    res = await recvUSB(64)
    let resdata = await usbDeviceCommunicationThruEXResponse(res)

    if (resdata.status === true) {
      clearInterval(intervalId)
      resdata.IDm = resdata.data.slice(0, 8)
      resdata.PMm = resdata.data.slice(8, 16)
      resdata.systemCode = resdata.data.slice(16, 18)
      console.log('IDm: ', resdata.IDm)
      console.log('PMm:', resdata.PMm)
      console.log('systemCode: ', resdata.systemCode)

      let servicecode

      if (arrayToHex(resdata.systemCode) === '88 B4 ') {
        console.log('信徒カードです')
        servicecode = 0x00
        resultData.systemCode = 0
      } else if (arrayToHex(resdata.systemCode) === '85 59 ') {
        console.log('職員カードです')
        servicecode = 0x11
        resultData.systemCode = 1
      } else {
        console.log('不明なカードです')
        resultData.systemCode = 2
        return
      }

      // Read Without Encryption (felica lite-s)
      const readWithoutEncryption = [
        0x06,
        ...resdata.IDm,
        0x01,
        0x0b,
        servicecode, // felica lite-s の場合は0x00 felica standardは0x11
        0x01,
        0x80,
        0x00,
      ]

      const readWithoutEncryptionCom = await usbDeviceCommunicationThruEX(
        readWithoutEncryption,
      )

      await sendUSB(readWithoutEncryptionCom)
      res = await recvUSB(64)
      resdata = await usbDeviceCommunicationThruEXResponse(res)
      const hexArray = arrayToHex(resdata.data).split(' ')
      const slicedHexArray = hexArray.slice(11, hexArray.length - 1)
      const hexString = slicedHexArray.join('')
      console.log({ hexString })
      const ascii = hexToAscii(hexString)
      console.log({ ascii })
      resultData.cardNumber = ascii
    } else {
      console.log('カードが見つかりませんでした')
    }
  }

  // USB デバイスクローズ
  const usbDeviceClose = async () => {
    console.log('usbDeviceClose')
    // RC-S300 コマンド
    const endTransparent = [0xff, 0x50, 0x00, 0x00, 0x02, 0x82, 0x00, 0x00]
    const turnOff = [0xff, 0x50, 0x00, 0x00, 0x02, 0x83, 0x00, 0x00]

    await sendUSB(turnOff, 'Turn Off RF')
    await sleep(50)
    await recvUSB(64)
    await sleep(50)

    await sendUSB(endTransparent, 'End Transeparent Session')
    await recvUSB(64)

    // USBデバイスの指定インターフェイスを排他アクセスを解放する
    await usbDevice.releaseInterface(usbConfiguration.interfaceNum)
    await usbDevice.close() // USBデバイスセッション終了

    return
  }

  await usbDeviceConnect()
  await usbDeviceOpen()
  await felica()
  await usbDeviceClose()
  return resultData
}

export default felicaRead
