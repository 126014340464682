import axios from 'axios'

interface CardData {
  data: {
    results: string
  }
  error: {}
  error_code?: 0
}

export const getCardID = async (card_number: string): Promise<CardData> => {
  const GETID_URL = process.env.REACT_APP_API_GETID_URL
  const response = await axios
    .get(GETID_URL + `?card_id=${card_number}`)
    .then((response: any) => {
      return response.data
    })
    .catch((error: any) => {
      console.log(error)
      return { error_code: 1, error }
    })
  return response
}
