import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { BackgroundImages } from '../components/BackgroundImages';

interface Props {
  branch: string,
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  handleReport: (event: React.MouseEvent<HTMLButtonElement>) => void,
  inputEl: React.RefObject<HTMLInputElement>,
}

export const Keyboard: FC<Props> = (props) => {

    const navigate = useNavigate();
    const handleBack = () => {
        navigate(`/${props.branch}/tb`);
    }

    return (
    <>
        <BackgroundImages category={0} />
        <button className="relative bg-white rounded-md py-2 px-2 font-bold border border-black text-3xl" onClick={handleBack} style={{ top: "-0px", left: "-350px" }} >戻る</button>
        <div className="flex justify-content-between align-items-center">
            <h1 className="text-center font-bold tracking-wide pt-2 p-0 text-3xl" style={{ textShadow: "1px 1px 2px white", marginTop: '-55px' }}>あなたの信徒IDを打ち込んでください</h1>
        </div>
        <div className="mx-auto max-w-lg text-center mt-6 w-full">
            <input type="number" pattern="[0-9]*" ref={props.inputEl} maxLength={10} className="bg-white rounded-lg py-2 px-4 block w-full appearance-none leading-normal border border-black text-5xl text-center" disabled />
            <div className="mx-auto max-w-lg text-center mt-6 w-full">
                <div className="keyboard-row grid grid-cols-3 gap-4">
                    <button className="bg-white rounded-md py-4 px-1 mr-1 mb-1 font-bold border border-black text-5xl" onClick={props.handleClick}>1</button>
                    <button className="bg-white rounded-md py-4 px-1 mr-1 mb-1 font-bold border border-black text-5xl" onClick={props.handleClick}>2</button>
                    <button className="bg-white rounded-md py-4 px-1 mr-1 mb-1 font-bold border border-black text-5xl" onClick={props.handleClick}>3</button>
                </div>
                <div className="keyboard-row grid grid-cols-3 gap-4">
                    <button className="bg-white rounded-md py-4 px-1 mr-1 mb-1 font-bold border border-black text-5xl" onClick={props.handleClick}>4</button>
                    <button className="bg-white rounded-md py-4 px-1 mr-1 mb-1 font-bold border border-black text-5xl" onClick={props.handleClick}>5</button>
                    <button className="bg-white rounded-md py-4 px-1 mr-1 mb-1 font-bold border border-black text-5xl" onClick={props.handleClick}>6</button>
                </div>
                <div className="keyboard-row grid grid-cols-3 gap-4">
                    <button className="bg-white rounded-md py-4 px-1 mr-1 mb-1 font-bold border border-black text-5xl" onClick={props.handleClick}>7</button>
                    <button className="bg-white rounded-md py-4 px-1 mr-1 mb-1 font-bold border border-black text-5xl" onClick={props.handleClick}>8</button>
                    <button className="bg-white rounded-md py-4 px-1 mr-1 mb-1 font-bold border border-black text-5xl" onClick={props.handleClick}>9</button>
                </div>
                <div className="keyboard-row grid grid-cols-3 gap-4">
                    <div></div>
                    <button className="bg-white rounded-md py-4 px-1 mr-1 mb-1 font-bold border border-black text-5xl" onClick={props.handleClick}>0</button>
                    <button className="bg-white rounded-md py-4 px-1 mr-1 mb-1 font-bold border border-black text-5xl" onClick={props.handleClick}>×</button>
                </div>
                <div className="keyboard-row grid grid-cols-1 gap-4 mt-6">
                    <button className="bg-white rounded-md py-3 px-1 mr-1 mb-1 font-bold border border-black text-5xl" onClick={props.handleReport}>奉告する</button>
                </div>
            </div>
        </div>
    </>
    );
};
