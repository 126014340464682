import { dataviewToArray } from './dataviewToArray'

interface retVal {
  status: boolean
  IDm: number[]
  PMm: number[]
  systemCode: number[]
  Length: number
  allData: number[]
  responseCode: number | null
  data: number[]
}

// communicateThruEX レスポンスデータを分解
export const usbDeviceCommunicationThruEXResponse = async (argRes: any) => {
  let data = dataviewToArray(argRes.data)
  let retVal: retVal = {
    status: false,
    IDm: [],
    PMm: [],
    systemCode: [],
    Length: 0,
    allData: [],
    responseCode: null,
    data: [],
  }
  // レスポンスデータ長の取得
  let v = data.indexOf(0x97) // レスポンスデータから 0x97 の位置を求める
  if (v >= 0) {
    let w = v + 1 // 0x97 の次にデータ長が設定されている。(128バイト以上は未考慮です)
    retVal.Length = data[w]
    if (retVal.Length > 0) {
      retVal.allData = data.slice(w + 1, w + retVal.Length + 1) // 全レスポンスデータ切り出す
      retVal.status = true
      retVal.responseCode = retVal.allData[1] // レスポンスコード
      // レスポンスデータ(レングス、レスポンスコードを除いたデータ)
      retVal.data = retVal.allData.slice(2, retVal.allData.length + 1)
    }
  }
  return retVal
}
