import React, { FC, useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { BackgroundImages } from '../components/BackgroundImages';

interface ResultState {
  count_this_year: number,
  count_total: number,
  branch: string,
}

const ResultPage: FC = () => {

  const [navigateFlag, setNavigateFlag] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const resultstate = location.state as ResultState;
  useEffect(() => {
    if(navigateFlag) {
      navigate(`/${resultstate.branch}/tb`);
    }
  }, [navigate, resultstate, navigateFlag]);

  useEffect(() => {
    setTimeout(() => {
      setNavigateFlag(true)
    }, 5000);
  }, []);

  const blockBrowserBack = useCallback(() => {
    window.history.go(3)
  }, [])

  useEffect(() => {
      window.history.pushState(null, '', window.location.href)

      window.addEventListener('popstate', blockBrowserBack)

      return () => {
          window.removeEventListener('popstate', blockBrowserBack)
      }
  }, [blockBrowserBack])

  return (
    <div className="bg-center bg-cover h-screen flex flex-col justify-center items-center">
      <BackgroundImages category={0} />
      <h1 className='text-center tracking-wide pt-0 p-0 text-5xl font-bold' style={{ textShadow: "3px 3px 3px white" }}>おかえりなさい</h1>
      <div className='mt-10 p-4'>
        <div className="flex bg-gray-50 py-10" style={{ opacity: 0.75 }}>
            <h1 className='text-6xl font-bold mx-20 text-shadow-lg' style={{ textShadow: "1px 1px 2px white" }}>本年: </h1>
            <h1 className='text-6xl font-bold text-b94047 mx-20 text-shadow-md' style={{ textShadow: "3px 3px 3px white" }}>{resultstate.count_this_year} <span className='text-black'>回</span></h1>
        </div>
        <div className="flex bg-gray-50 py-10" style={{ opacity: 0.75 }}>
            <h1 className='text-6xl font-bold mt-6 mx-20' style={{ textShadow: "3px 3px 3px white" }}>通算: </h1>
            <h1 className='text-6xl font-bold mt-6 text-b94047 mx-20' style={{ textShadow: "3px 3px 3px white" }}>{resultstate.count_total} <span className='text-black'>回</span></h1>
        </div>
      </div>
    </div>
  );
};

export default ResultPage;
