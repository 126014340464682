import React, { FC, useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import { BackgroundImages } from '../components/BackgroundImages'
import { postReportAndCount } from '../services/postReportAndCount';

const QR: FC = () => {
    const inputEl = useRef<HTMLInputElement | null>(null);
    const [report, setReport] = useState(null);
    const [countThisYear, setCountThisYear] = useState<number>(0);
    const [countTotal, setCountTotal] = useState<number>(0);
    const [errorCode, setErrorCode] = useState<number>(0);
    const navigate = useNavigate();
    const branch_id = "28001";
    const branch_name = "qr";

    const handleReport: React.MouseEventHandler<HTMLButtonElement> = async () => {
        if(inputEl.current === null || inputEl.current.value.length !== 10) return
        const report_response = await postReportAndCount(inputEl.current.value, branch_id);
        if(report_response.error?.response?.status === 401) {
            setErrorCode(3);
            return;
        }
        if(report_response.error_code) {
            setErrorCode(1)
            return;
        }
        if(JSON.parse(report_response.data.result).error !== null) {
            setErrorCode(1)
            return;
        }
        if(report_response) {
          setReport(JSON.parse(report_response.data.result).message);
          setCountTotal(JSON.parse(report_response.data.result).info.count_total);
          setCountThisYear(JSON.parse(report_response.data.result).info.count_this_year);
        }
    };

    const handleValidation: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        if(inputEl.current !== null) {
            if (inputEl.current.value.length > 10) {
                inputEl.current.value = inputEl.current.value.slice(0, -1);
            }
        }
    };

    useEffect(() => {
        if(errorCode !== 0) {
            navigate(`/error`, {state: { branch: branch_name, error_code: errorCode }});
        }
    }, [navigate, errorCode]);

    const blockBrowserBack = useCallback(() => {
        window.history.go(1)
    }, [])

    useEffect(() => {

        window.history.pushState(null, '', window.location.href)
    
        window.addEventListener('popstate', blockBrowserBack)
        window.addEventListener('pageshow', blockBrowserBack)
    
        return () => {
            window.removeEventListener('popstate', blockBrowserBack)
        }
    }, [blockBrowserBack])

    return (
        <div className="flex flex-col justify-center items-center relative w-full h-full">
            <BackgroundImages category={0} />
            {report === null ? (
                <>
                    <h1 className="text-center tracking-wide p-2 text-3xl font-bold" style={{ textShadow: "3px 3px 3px white" }}>万代院参拝奉告</h1>
                    <h1 className="text-center tracking-wide p-2 mt-4 text-3xl font-bold" style={{ textShadow: "3px 3px 3px white" }}>IDを入力してください</h1>
                    <div className="mx-auto text-center mt-10">
                        <input type="number" pattern="[0-9]*" ref={inputEl} maxLength={10} className="border border-black bg-white rounded-lg py-2 px-16 block w-full appearance-none leading-normal text-center" onChange={handleValidation} />
                        <button className="bg-white rounded-md py-4 px-4 mr-2 mb-2 mt-10 font-bold border border-black" onClick={handleReport}>奉告する</button>
                    </div>
                </>
            )
            :
            (
                <>
                    <h1 className='text-center tracking-wide pt-0 p-0 text-3xl' style={{ textShadow: "3px 3px 3px white" }}>おかえりなさい</h1>
                    <div className="flex bg-gray-50 mt-10 p-4 py-6" style={{ opacity: 0.75, width: '80%' }}>
                        <h1 className='text-4xl font-normal mx-10 text-shadow-lg' style={{ textShadow: "1px 1px 2px white" }}>本年: </h1>
                        <h1 className='text-4xl font-normal text-b94047 mx-10 text-shadow-md' style={{ textShadow: "3px 3px 3px white" }}>{countThisYear} <span className='text-black'>回</span></h1>
                    </div>
                    <div className="flex bg-gray-50 p-4 py-6" style={{ opacity: 0.75, width: '80%' }}>
                        <h1 className='text-4xl font-normal mt-6 mx-10' style={{ textShadow: "3px 3px 3px white" }}>通算: </h1>
                        <h1 className='text-4xl font-normal mt-6 text-b94047 mx-10' style={{ textShadow: "3px 3px 3px white" }}>{countTotal} <span className='text-black'>回</span></h1>
                    </div>
                </>
            )
            }
        </div>
    );
};

export default QR;
