import React, { FC, MouseEvent } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import OyasonoFelicaReadPage from './pages/bandaiin/felicaread';
import OyasonoKeyInputPage from './pages/bandaiin/inputreport';
import ReadPage from './pages/readpage';
import ResultPage from './pages/resultpage';
import QRPage from './pages/qr';
import ErrorPage from './pages/errorpage';
import AuthContext from './components/AuthContext';

const App: FC = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const handleRightClick = (event: MouseEvent) => {
    event.preventDefault();
  }

  return (
    <div className="bg-center bg-cover h-screen flex justify-center items-center" onContextMenu={handleRightClick}>
      <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
        {/* felica読み取り画面(万代院)
            ID入力画面(万代院)
            エラー画面
        */}
        <Router>
          <Routes>
            <Route path="/" element={<OyasonoFelicaReadPage />} />
            <Route path="/bandaiin/tb" element={<OyasonoFelicaReadPage />} />
            <Route path="/bandaiin/keyboard" element={<OyasonoKeyInputPage />} />
            <Route path="/read" element={<ReadPage />} />
            <Route path="/PCKx5SGJoXtjr" element={<QRPage />} />
            <Route path="/result" element={<ResultPage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </AuthContext.Provider>
    </div>
  );
};

export default App;
