// USBデバイス Endpoint の取得
export const getEndPoint = (
  argInterface: USBInterface,
  argVal: 'in' | 'out',
): USBEndpoint => {
  let retVal
  for (const val of argInterface.alternate.endpoints) {
    if (val.direction === argVal) {
      retVal = val
    }
  }
  if (!retVal) {
    throw new Error('Endpoint not found')
  }
  return retVal
}
