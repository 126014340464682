// communicateThruEX を使って FeliCa カードを操作する。
export const usbDeviceCommunicationThruEX = async (argCom: any) => {
  // RC-S300 コマンド　communicateThruEX
  const communicateThruEX = [0xff, 0x50, 0x00, 0x01, 0x00]
  // RC-S300 コマンド　communicateThruEX フッター
  const communicateThruEXFooter = [0x00, 0x00, 0x00]
  // FeliCa リクエストヘッダー
  const felicaHeader = [0x5f, 0x46, 0x04]
  // FeliCa リクエストオプション
  const felicaOption = [0x95, 0x82]
  // タイムアウト(ms)
  let felicaTimeout = 100

  // FeliCa Lite-S コマンドにレングスを付加
  let felicaComLen = argCom.length + 1
  let felicaCom = [felicaComLen, ...argCom]

  // FeliCa Lite-S リクエストヘッダーを付加
  felicaTimeout *= 1e3 // マイクロ秒へ変換
  let felicaReq = [...felicaHeader] // リクエストヘッダー
  felicaReq.push(
    255 & felicaTimeout,
    (felicaTimeout >> 8) & 255,
    (felicaTimeout >> 16) & 255,
    (felicaTimeout >> 24) & 255,
  ) // タイムアウト <<リトルエンディアン>> 4バイト
  felicaReq.push(...felicaOption)
  felicaReq.push((felicaComLen >> 8) & 255, 255 & felicaComLen) // コマンドレングス
  felicaReq.push(...felicaCom) // リクエストコマンド

  // communicateThruEX コマンド作成
  let felicaReqLen = felicaReq.length
  let cTX = [...communicateThruEX]
  cTX.push((felicaReqLen >> 8) & 255, 255 & felicaReqLen) // リクエストレングス
  cTX.push(...felicaReq)
  cTX.push(...communicateThruEXFooter)

  return cTX
}
