import React, { FC, useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { BackgroundImages } from '../components/BackgroundImages';

interface errorState {
  branch: string,
  error_code?: number,
}

const Error: FC = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const errorState = location.state as errorState;
  const [errorMessage, setErrorMessage] = useState<string>('エラーが発生しました。<br />管理者を呼んでください。');

  useEffect(() => {
    if (errorState.error_code === 2) {
      setErrorMessage('親苑にいることが認識できませんでした。<br />親苑に到着しましたら、再読み込みしてください。')
    }
    if (errorState.error_code === 3) {
      setErrorMessage('入力されたIDに誤りがあります。<br />再度入力してください。')
    }
    if (errorState.error_code === 4) {
      setErrorMessage('登録していないカードです。')
    }
  }, [navigate, errorState, errorState.branch, errorState.error_code]);

  useEffect(() => {
    setTimeout(() => {
      if (errorState.branch === 'qr' || errorState.branch === null) {
        navigate(`/PCKx5SGJoXtjr`);
      } else {
        navigate(`/${errorState.branch}/tb`);
      }
    }, 5000);
  }, [navigate, errorState, errorState.branch]);

  const isMobile = window.matchMedia("(max-width: 767px)").matches;

  const handleBack = () => {
      // setNavigateFlag(false)
      if (errorState.branch === 'qr') {
        navigate(`/PCKx5SGJoXtjr`);
      } else {
        navigate(`/${errorState.branch}/tb`);
      }
  }

  const handleSPBack = () => {

    if (errorState.branch === 'qr') {
      navigate(`/PCKx5SGJoXtjr`);
    } else {
      navigate(`/${errorState.branch}/tb`);
    }
  }

  const blockBrowserBack = useCallback(() => {
    window.history.go(3)
  }, [])

  useEffect(() => {
      window.history.pushState(null, '', window.location.href)

      window.addEventListener('popstate', blockBrowserBack)

      return () => {
          window.removeEventListener('popstate', blockBrowserBack)
      }
  }, [blockBrowserBack])

  return (
    <div className="bg-center bg-cover h-screen flex justify-center items-center">
      <BackgroundImages category={isMobile ? 2 : 0} />
      <div className="flex bg-gray-50 px-10 py-20 md:px-20 md:py-40" style={{ opacity: 0.75 }}>
        <h2 className="text-center md:text-3xl sm:text-xl font-bold text-red-700">{errorMessage.split('<br />').map((line, index) => {
          return (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          );
        })}
        {
          isMobile ?
          <button className="bg-white rounded-md py-2 px-2 font-bold border border-black text-md text-black mt-10" onClick={handleSPBack}>再試行</button> :
          <button className="relative bg-white rounded-md py-2 px-2 font-bold border border-black text-3xl text-black mt-10" onClick={handleBack}>戻る</button>
        }
        </h2>
      </div>
    </div>
  )
}

export default Error;