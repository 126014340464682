import React, { FC, useState, useEffect } from 'react';

const topimage = '/backgroundImages/top_image.jpg'
const image1 = '/backgroundImages/image01.jpg';
const image2 = '/backgroundImages/image02.jpg';
const image3 = '/backgroundImages/image03.jpg';
const image4 = '/backgroundImages/image04.jpg';
const image5 = '/backgroundImages/image05.jpg';
const image6 = '/backgroundImages/image06.jpg';

interface Props {
  category: number;
}

const getRandomNumber = (start: number, end: number) => {
  return start + Math.floor(Math.random() * (end + 1))
}

// 親要素にposition: relativeを指定すること
export const BackgroundImages: FC<Props> = (props) => {

  let images;
  images =  [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
  ];
  // props.category === 0 は通常のページ
  // props.category === 1 はトップページのみ(固定背景画像)
  if(props.category === 1) { images = [topimage]; }
  const [prepare, setPrepare] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(getRandomNumber(0, images.length - 1))

  useEffect(() => {
    const num = getRandomNumber(0, images.length - 1)
    setCurrentIndex(num)
    setPrepare(true)
  }, [props, images])

  return (
    prepare ?
    <div
      className="bg-center bg-cover h-screen flex flex-col justify-center items-center"
      style={{
        backgroundImage: `url(${images ? images[currentIndex] : topimage})`,
        height: '100%',
        width: '100%',
        // marginBottom: '20px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        backgroundBlendMode: 'lighten',
        boxShadow: 'inset 0px 0px 8px 8px #fff',
        borderRadius: '20px',
        position: 'absolute',
        zIndex: '-1'
      }}
    ></div> : <></>
  )
}